import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledFlexBox } from '@components/containers/FlexBox';

import Button from '@components/elements/Button';
import Icon, { StyledIcon } from '@components/elements/Icon';
import style from '@constants/style';

const StyledIconButtonContent = styled(StyledFlexBox)`
  justify-content: space-between;
  align-items: center;

  ${StyledIcon} {
    margin-right: ${style.margin.regular};
  }
`;

class IconButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { focus: false };
    this.invertIcon = this.invertIcon.bind(this);
  }

  invertIcon() {
    const { focus } = this.state;
    this.setState({ focus: !focus });
  }

  render() {
    const {
      disabled,
      onClick,
      to,
      primaryColor,
      secondaryColor,
      inverted,
      small,
      big,
      href,
      primaryIcon,
      secondaryIcon,
      iconHeight,
      iconWidth,
      children,
    } = this.props;
    const { focus } = this.state;
    return (
      <Button
        small={small}
        disabled={disabled}
        to={to}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        inverted={inverted}
        big={big}
        href={href}
        onClick={onClick}
        primaryIcon={primaryIcon}
        secondaryIcon={secondaryIcon}
        onMouseEnter={this.invertIcon}
        onMouseLeave={this.invertIcon}
      >
        <StyledIconButtonContent>
          <Icon
            iconHeight={iconHeight}
            iconWidth={iconWidth}
            icon={!focus ? primaryIcon : secondaryIcon}
          />
          {children}
        </StyledIconButtonContent>
      </Button>
    );
  }
}

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  primaryIcon: PropTypes.oneOfType([
    // eslint-disable-next-line react/no-typos
    PropTypes.elementType,
    PropTypes.string,
  ]).isRequired,
  secondaryIcon: PropTypes.oneOfType([
    // eslint-disable-next-line react/no-typos
    PropTypes.elementType,
    PropTypes.string,
  ]).isRequired,
  iconHeight: PropTypes.string,
  iconWidth: PropTypes.string,
  disabled: PropTypes.bool,
  inverted: PropTypes.bool,
  small: PropTypes.bool,
  big: PropTypes.bool,
};

IconButton.defaultProps = {
  disabled: false,
  onClick: null,
  to: null,
  href: null,
  primaryColor: style.colours.primaryDark,
  secondaryColor: style.colours.primaryLight,
  iconWidth: null,
  iconHeight: null,
  inverted: false,
  small: false,
  big: false,
};

export default IconButton;
