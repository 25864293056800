import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import LoggedPage from '@components/containers/LoggedPage';
import Card from '@components/containers/Card';
import FlexBox from '@components/containers/FlexBox';

import PDFViewer from '@components/modules/PDFViewer';

import IconButton from '@components/elements/IconButton';
import Loader from '@components/elements/Loader';

import { showModal } from '@redux/actions/ui';

import { bankIdRequests } from '@constants/bankId';
import modalTypes from '@components/modals/types';
import style from '@constants/style';
import urls from '@constants/urls';

import getUrl from '@utils/getUrl';

import BankidWhite from '@assets/img/icons/bankid_white.svg?inline';
import BankidBlue from '@assets/img/icons/bankid_blue.svg?inline';

const Signera = ({ accountId, bankAccountId }) => {
  const loading = useSelector((state) => state.documents.loading);
  const documentUrl = useSelector((state) => state.documents.documentUrl);
  const documentType = useSelector((state) => state.documents.documentType);

  const dispatch = useDispatch();

  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(showModal({
        type: modalTypes.BANK_ID,
        props: {
          request: bankIdRequests.SIGN_DOCUMENT,
          payload: {
            accountId,
            bankAccountId,
            documentType,
          },
        },
      }));
    },
    [dispatch, bankAccountId, accountId, documentType],
  );

  return (
    <LoggedPage
      title="Signera autogiromedgivande"
      previousUrl={getUrl(urls.HOME.AUTOGIRO.VALID_BANK_ACCOUNT, { accountId })}
      fullWidth
    >
      {loading ? (
        <Loader />
      ) : (
        <Card>
          <FlexBox
            alignItems="stretch"
            breakSize="tablet"
            maxHeight="55vh"
            maxHeightLargeScreen="65vh"
          >
            {documentUrl && <PDFViewer url={documentUrl} />}
          </FlexBox>
          <FlexBox justifyContent="center" margin="regular">
            <IconButton
              small
              onClick={onClick}
              iconHeight={style.fontSize.huge}
              primaryIcon={BankidWhite}
              secondaryIcon={BankidBlue}
            >
              Signera medgivande
            </IconButton>
          </FlexBox>
        </Card>
      )}
    </LoggedPage>
  );
};

Signera.propTypes = {
  accountId: PropTypes.string.isRequired,
  bankAccountId: PropTypes.string.isRequired,
};

export default Signera;
