import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import styled from 'styled-components';

import style from '@constants/style';
import Loader from '@components/elements/Loader';

const workerUrl = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = workerUrl;

const PAGE_PADDING = 0.5;

const StyledPDFPage = styled(Page)`
  canvas {
    padding: ${PAGE_PADDING / 2}rem ${PAGE_PADDING}rem;
    margin: auto;
  }

  &:first-child canvas {
    padding-top: ${PAGE_PADDING}rem;
  }

  &:last-child canvas {
    padding-bottom: ${PAGE_PADDING}rem;
  }
`;

const StyledDocument = styled(Document)`
  background-color: ${style.colours.secondaryDarkGray4};
  overflow: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;

  .react-pdf__message {
    margin: auto;
    color: white;
  }
`;

const PDFViewer = ({ url }) => {
  const [pageCount, setPageCount] = useState(null);
  const [width, setWidth] = useState();

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setPageCount(numPages);
  }, []);

  useEffect(() => {
    setWidth(Math.min(global.innerWidth - (PAGE_PADDING * 2 * 16), 1024));
  }, []);

  return (
    <StyledDocument
      file={url}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={<Loader />}
    >
      {Array.from(
        new Array(pageCount),
        (_, index) => (
          <StyledPDFPage
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            loading={null}
            width={width}
          />
        ),
      )}
    </StyledDocument>
  );
};

PDFViewer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default PDFViewer;
